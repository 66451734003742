// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-migration-modernization-devops-transformation-js": () => import("./../src/pages/Migration-Modernization/DevopsTransformation.js" /* webpackChunkName: "component---src-pages-migration-modernization-devops-transformation-js" */),
  "component---src-pages-mails-privacypolicy-js": () => import("./../src/pages/Mails/privacypolicy.js" /* webpackChunkName: "component---src-pages-mails-privacypolicy-js" */),
  "component---src-pages-services-aws-managed-js": () => import("./../src/pages/Services/AwsManaged.js" /* webpackChunkName: "component---src-pages-services-aws-managed-js" */),
  "component---src-pages-solutions-sublanding-js": () => import("./../src/pages/Solutions/Sublanding.js" /* webpackChunkName: "component---src-pages-solutions-sublanding-js" */),
  "component---src-pages-solutions-secure-business-js": () => import("./../src/pages/Solutions/SecureBusiness.js" /* webpackChunkName: "component---src-pages-solutions-secure-business-js" */),
  "component---src-pages-what-we-do-area-of-expertise-js": () => import("./../src/pages/WhatWeDo/AreaOfExpertise.js" /* webpackChunkName: "component---src-pages-what-we-do-area-of-expertise-js" */),
  "component---src-pages-services-office-migration-js": () => import("./../src/pages/Services/OfficeMigration.js" /* webpackChunkName: "component---src-pages-services-office-migration-js" */),
  "component---src-pages-services-iaass-js": () => import("./../src/pages/Services/Iaass.js" /* webpackChunkName: "component---src-pages-services-iaass-js" */),
  "component---src-pages-solutions-daas-js": () => import("./../src/pages/Solutions/Daas.js" /* webpackChunkName: "component---src-pages-solutions-daas-js" */),
  "component---src-pages-services-protect-cloud-js": () => import("./../src/pages/Services/ProtectCloud.js" /* webpackChunkName: "component---src-pages-services-protect-cloud-js" */),
  "component---src-pages-solutions-business-js": () => import("./../src/pages/Solutions/Business.js" /* webpackChunkName: "component---src-pages-solutions-business-js" */),
  "component---src-pages-solutions-disaster-js": () => import("./../src/pages/Solutions/Disaster.js" /* webpackChunkName: "component---src-pages-solutions-disaster-js" */),
  "component---src-pages-migration-modernization-infra-sturcture-transformation-js": () => import("./../src/pages/Migration-Modernization/InfraSturctureTransformation.js" /* webpackChunkName: "component---src-pages-migration-modernization-infra-sturcture-transformation-js" */),
  "component---src-pages-migration-modernization-application-transformation-js": () => import("./../src/pages/Migration-Modernization/ApplicationTransformation.js" /* webpackChunkName: "component---src-pages-migration-modernization-application-transformation-js" */),
  "component---src-pages-solutions-microservice-js": () => import("./../src/pages/Solutions/Microservice.js" /* webpackChunkName: "component---src-pages-solutions-microservice-js" */),
  "component---src-pages-solutions-hybridcloud-js": () => import("./../src/pages/Solutions/Hybridcloud.js" /* webpackChunkName: "component---src-pages-solutions-hybridcloud-js" */),
  "component---src-pages-resources-featured-js": () => import("./../src/pages/Resources/Featured.js" /* webpackChunkName: "component---src-pages-resources-featured-js" */),
  "component---src-pages-resources-blog-js": () => import("./../src/pages/Resources/Blog.js" /* webpackChunkName: "component---src-pages-resources-blog-js" */),
  "component---src-pages-resources-blogone-js": () => import("./../src/pages/Resources/Blogone.js" /* webpackChunkName: "component---src-pages-resources-blogone-js" */),
  "component---src-pages-what-we-do-case-study-js": () => import("./../src/pages/WhatWeDo/CaseStudy.js" /* webpackChunkName: "component---src-pages-what-we-do-case-study-js" */),
  "component---src-pages-company-aboutus-js": () => import("./../src/pages/Company/Aboutus.js" /* webpackChunkName: "component---src-pages-company-aboutus-js" */),
  "component---src-pages-company-careers-js": () => import("./../src/pages/Company/Careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-mob-tab-js": () => import("./../src/pages/Company/MobTab.js" /* webpackChunkName: "component---src-pages-company-mob-tab-js" */),
  "component---src-pages-case-studies-softwaredefined-js": () => import("./../src/pages/CaseStudies/Softwaredefined.js" /* webpackChunkName: "component---src-pages-case-studies-softwaredefined-js" */),
  "component---src-pages-case-studies-realtime-js": () => import("./../src/pages/CaseStudies/Realtime.js" /* webpackChunkName: "component---src-pages-case-studies-realtime-js" */),
  "component---src-pages-case-studies-storagemigration-js": () => import("./../src/pages/CaseStudies/Storagemigration.js" /* webpackChunkName: "component---src-pages-case-studies-storagemigration-js" */),
  "component---src-pages-case-studies-networkdesign-js": () => import("./../src/pages/CaseStudies/Networkdesign.js" /* webpackChunkName: "component---src-pages-case-studies-networkdesign-js" */),
  "component---src-pages-case-studies-storagetier-js": () => import("./../src/pages/CaseStudies/Storagetier.js" /* webpackChunkName: "component---src-pages-case-studies-storagetier-js" */),
  "component---src-pages-case-studies-disasterrecovery-js": () => import("./../src/pages/CaseStudies/Disasterrecovery.js" /* webpackChunkName: "component---src-pages-case-studies-disasterrecovery-js" */),
  "component---src-pages-company-contact-js": () => import("./../src/pages/Company/Contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-company-thankyou-js": () => import("./../src/pages/Company/Thankyou.js" /* webpackChunkName: "component---src-pages-company-thankyou-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-case-studies-js": () => import("./../src/pages/CaseStudies/CaseStudies.js" /* webpackChunkName: "component---src-pages-case-studies-case-studies-js" */),
  "component---src-pages-home-getintouch-js": () => import("./../src/pages/Home/Getintouch.js" /* webpackChunkName: "component---src-pages-home-getintouch-js" */),
  "component---src-pages-home-hexagon-js": () => import("./../src/pages/Home/Hexagon.js" /* webpackChunkName: "component---src-pages-home-hexagon-js" */),
  "component---src-pages-home-index-js": () => import("./../src/pages/Home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-home-partners-js": () => import("./../src/pages/Home/Partners.js" /* webpackChunkName: "component---src-pages-home-partners-js" */),
  "component---src-pages-home-success-stories-js": () => import("./../src/pages/Home/SuccessStories.js" /* webpackChunkName: "component---src-pages-home-success-stories-js" */),
  "component---src-pages-home-tabs-js": () => import("./../src/pages/Home/Tabs.js" /* webpackChunkName: "component---src-pages-home-tabs-js" */),
  "component---src-pages-resources-casestudy-js": () => import("./../src/pages/Resources/Casestudy.js" /* webpackChunkName: "component---src-pages-resources-casestudy-js" */),
  "component---src-pages-services-primary-cloud-hosting-services-js": () => import("./../src/pages/Services/PrimaryCloudHostingServices.js" /* webpackChunkName: "component---src-pages-services-primary-cloud-hosting-services-js" */),
  "component---src-pages-services-protect-cloud-copy-js": () => import("./../src/pages/Services/ProtectCloud - Copy.js" /* webpackChunkName: "component---src-pages-services-protect-cloud-copy-js" */),
  "component---src-pages-solutions-iaas-js": () => import("./../src/pages/Solutions/Iaas.js" /* webpackChunkName: "component---src-pages-solutions-iaas-js" */)
}

